import React, { createContext, useState, useEffect } from 'react'
import axios from 'axios';

export const ShopContext = createContext(null);

export const ShopContextProvider = (props) => {

  let [store, setStore] = useState('');
  
  const getStoreName = (props)=>{
     setStore(props);
  }

  const BASE_URL = process.env.REACT_APP_API_URL;
  const PRODUCT_URL = `${BASE_URL}/products/${store}`;
  const PROFILE_URL = `${BASE_URL}/profiles/${store}`;
  const LOCATION_URL = `${BASE_URL}/locations/${store}`;
  const LAST_ID_URL = `${BASE_URL}/product`;

  // const LOCATION_URL = `http://127.0.0.1:8000/api/locations/${store}`;

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [isLoading, setIsLoading] = useState(true);
  const [dataIsLoading, setDataIsLoading] = useState(true);
  localStorage.setItem('isLoading', isLoading);


  //Get products from api

  const [posts, setPosts] = useState([]);
   useEffect(()=>{
    axios.get(PRODUCT_URL).then(res=>{
       setPosts (res.data)
       setDataIsLoading(false);
     }).catch(err=>{
       console.log(err)
     })
   }, [PRODUCT_URL])

//Get profile from api
const [profile, setProfile] = useState([]);
useEffect(()=>{
  getProfileData();
}, [PROFILE_URL]);

const getProfileData = async () =>{
 await axios.get(PROFILE_URL).then((res)=>{
   setProfile(res.data);
   setIsLoading(false);
  });

}

//        useEffect(()=>{
//   axios.get(PROFILE_URL).then((res)=>{
//     setProfile(res.data);
//     setIsLoading(false);
   
//   })
// },[PROFILE_URL])


let [lastId, setLastId] = useState(0);
const GetDefaultCart = () => {
  let cart = {};
useEffect(()=>{

  axios.get(LAST_ID_URL).then(res=>{
    setLastId (res.data);
   const count = res.data;
  
    for (let i = 1; i < count + 1; i++) {
      cart[i] = 0;
    }
  
   }).catch(err=>{
     console.log(err)
   })
 }, [lastId])
 return cart;
   
};


const [location, setLocation] = useState([]);
//Get location from api

useEffect(()=>{
axios.get(LOCATION_URL).then((res)=>{
  setLocation(res.data);
  console.log(res);
}).catch(err=>{
  console.error(err);
})
},[LOCATION_URL])

console.log('this is location', location);

  const [item, setItem] = useState(posts);
  
  const menuItems = [...new Set(posts.map((product) => product.category_name))];

  const filterItem = (curcat) => {

    const newItem = posts.filter((newVal) => {
      return newVal.category_name === curcat;
    });
    setItem(newItem);
  };


  const arMenuItems = [...new Set(posts.map((product) => product.category_ar_name))];

  const arFilterItem = (curcat) => {
    const newItem = posts.filter((newVal) => {
      return newVal.category_ar_name === curcat;
    });
    setItem(newItem);
  }

  const [cartItems, setCartItems] = useState(GetDefaultCart()); 

  const getTotalCartAmount = () => {
    let totalAmount = 0;
    for (const item in cartItems) {
      if (cartItems[item] > 0) {
        let itemInfo = posts.find((product) => product.id === Number(item));
        totalAmount += cartItems[item] * itemInfo.price;
      }
    }
    return totalAmount;
  };

  const addToCart = (itemId) => {
    setCartItems((prev) => ({ ...prev, [itemId]: prev[itemId] + 1 }));
   
  };
  // console.log('cart items', cartItems);

  const removeFromCart = (itemId) => {
    setCartItems((prev) => ({ ...prev, [itemId]: prev[itemId] - 1 }));
  };

  const updateCartItemCount = (newAmount, itemId) => {
    setCartItems((prev) => ({ ...prev, [itemId]: newAmount }));
  };

  const checkout = () => {
    setCartItems(GetDefaultCart());
  };

  const [vertical, setVertical] = useState(true);
function handleVertical () {
  setVertical(!vertical);
}
  const contextValue = {
    cartItems,
    addToCart,
    updateCartItemCount,
    removeFromCart,
    getTotalCartAmount,
    checkout,
    filterItem,
    menuItems,
    arMenuItems,
    arFilterItem,
    setItem,
    item,
    handleShow,
    show,
    handleClose,
    posts, 
    profile,
    getStoreName,
    store,
    location,
    isLoading,
    dataIsLoading,
    vertical,
    handleVertical
    
  };

  return (
    <ShopContext.Provider value={contextValue}>
      {props.children}
    </ShopContext.Provider>
  );
};